:root {
  --background-dark: #000;
  --background-light: #fff;
  --color-dark: #000;
  --color-light: #fff;
  --color-gray: #A9A9A9;
  --border-bottom: 1px solid rgba(84, 84, 84, .7);
  --border-bottom2: 2px solid rgba(84, 84, 84, .7);
  --max-width-large: 880px;
  --max-width-small: 320px;
  --min-height-header-ladge: 119px;
  --min-height-header-small: 84px;
  --height-without-header-ladge: calc(100vh - 119px - 80px);
  --height-without-header-small: calc(100vh - 84px - 40px);
  --transition: opacity .2s ease-out;
  --opacity: .6;
  --width-form-register-ladge: 358px;
  --width-form-register-small: 260px;
}
