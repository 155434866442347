.content {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 918px;
  min-width: 320px;
}

.traveler {
  margin: 0;
  padding: 40px 19px 49px;
  max-width: 880px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
  }

  &__avatar {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: center;
    transition: opacity .3s ease-in-out;
    cursor: pointer;
  }

  &__change-avatar {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    width: 121px;
    height: 121px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: visibility .3s ease-in-out, background-color .3s ease-in-out;
    z-index: 2;
    visibility: hidden;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
      visibility: visible;

      &::after {
        content: url(../../images/pencil.svg);
      }
    }
  }

  &__middle {
    margin: 0;
    padding: 0 0 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__name-and-button {
    margin: 0;
    padding: 0 18px 0 0;
    display: flex;
    align-items: center;
  }

  &__name {
    margin: 0;
    padding: 0 20px 0 0;
    max-width: 467px;
    font-weight: 500;
    font-size: 42px;
    line-height: 1.1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button-correct {
    margin: 5px 0 0;
    padding: 0;
    border: 1px solid #FFF;
    width: 25px;
    height: 25px;
    background-color: #000;
    background-image: url(../../images/corrector.svg);
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: opacity .3s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }

  &__about {
    margin: 0;
    padding: 10px 0 0;
    max-width: 510px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button-add {
    margin: 0;
    padding: 0;
    width: 150px;
    height: 50px;
    border: 1px solid #FFF;
    border-radius: 2px;
    background-color: #000;
    background-image: url(../../images/plus.svg);
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: opacity .3s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
}

.traveler__avatar:hover+.traveler__change-avatar {
  visibility: visible;
}

.cards {
  box-sizing: border-box;
  max-width: 918px;
  width: 100%;
  margin: 0 auto;
  padding: 0 19px;

  &__list {
    margin: 0 auto;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 17px;
    list-style: none;
  }
}


@media screen and (max-width: 950px) {
  .cards {
    max-width: 619px;

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .traveler {
    margin: 0 auto;
    max-width: 282px;
    padding: 41px 19px 36px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;

    &__name-and-button {
      padding: 0;
    }

    &__name {
      padding-left: 28px;
      max-width: 254px;
      padding-right: 10px;
      font-size: 27px;
      line-height: 1.2;
    }

    &__middle {
      padding: 26px 0 0 0;
      flex-direction: column;
      align-items: center;
    }

    &__left {
      flex-direction: column;
      align-items: center;
    }

    &__change-avatar {
      left: 25px;
    }

    &__button-correct {
      width: 18px;
      height: 18px;
    }

    &__button-add {
      background-size: 16px;
      width: 282px;
    }

    &__about {
      padding: 9px 0 0;
      max-width: 282px;
      font-size: 14px;
      line-height: 1.2;
    }
  }
}

@media screen and (max-width: 650px) {
  .cards {
    max-width: 320px;

    &__list {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}