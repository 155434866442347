@import "vars";

@mixin padding-margin-null-auto() {
  padding: 0;
  margin: 0 auto;
}

@mixin theme-dark() {
  background-color: var(--background-dark);
  color: var(--color-light);
}

@mixin theme-dark-button() {
  background-color: var(--background-light);
  color: var(--color-dark);
}