.form {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  &__submit-container {
    margin: 0;
    padding-top: 216px;
  }

  &__submit {
    margin: 31px 0 0;
    padding: 14px 0;
    align-self: stretch;
    background: rgba(0, 0, 0, 1);
    border-radius: 2px;
    border: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    transition: opacity .3s ease-in-out;
    width: 100%;

    &:hover {
      opacity: 0.6;
    }

    &_disabled {
      background: #fff;
      color: rgba(196, 196, 196, 1);
      cursor: default;
      border: 1px solid rgba(196, 196, 196, 1);
      transition: none;
      pointer-events: none;
    }
  }

  &__input {
    width: 100%;
    padding: 13px 0 8px;
    margin: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: #000000;
    outline: none;

    &_type_error {
      border-bottom: 1px solid rgb(255, 0, 0);
    }

    &_dark {
      background: rgba(0, 0, 0, 1);
      color: rgba(255, 255, 255, 1);
      border-bottom: 2px solid #CCC;
    }
  }
}

@media screen and (max-width: 650px) {
  .form {
    &__submit {
      margin: 25px 0 0;
      font-size: 14px;
    }

    &__input {
      padding: 13px 0 8px;
    }
  }
}