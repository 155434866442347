.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.6s, opacity 0.3s ease-in-out;

  &_visible {
    visibility: visible;
    opacity: 1;
    z-index: 5;
  }

  &__close {
    position: absolute;
    top: -40px;
    right: -40px;
    background: none;
    background-image: url("../images/close_icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
    width: 32px;
    height: 32px;

    &:hover {
      opacity: 0.6;
    }
  }
}

@media screen and (max-width: 650px) {
  .popup {
    &__close {
      width: 20px;
      height: 20px;
      top: -36px;
      right: 0;
    }
  }
}