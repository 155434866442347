.popup {
  &__photo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__photo-container {
    max-height: 75vh;
    max-width: 75vw;
    position: relative;
  }

  &__photo-description {
    // margin: 0;
    padding: 10px 0 0;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    font-style: normal;
  }

}