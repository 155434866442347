input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 30px black inset;
}

* {
    padding: 0;
    margin: 0;
  }